import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "page-title-component" }
const _hoisted_2 = { class: "text flex" }
const _hoisted_3 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", null, [
        _createElementVNode("h1", null, _toDisplayString(_ctx.title), 1),
        (_ctx.subheading)
          ? (_openBlock(), _createElementBlock("h6", _hoisted_3, _toDisplayString(_ctx.subheading), 1))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}