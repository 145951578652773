
import { defineComponent } from 'vue';

import FooterComponent from '@/component/layout/Footer.component.vue';

export default defineComponent({
    name: 'PageContentComponent',

    components: {
        FooterComponent,
    },  

    setup() {},
});
