
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'ButtonComponent',

    props: {
        isGhost: Boolean,
    },

    setup() {},
});
