
import { computed, defineComponent } from 'vue';
import { useRoute } from 'vue-router';

import LinkComponent from '@/component/Link.component.vue';
import GitHubIcon from '@/component/icon/GitHubIcon.component.vue';
import LinkedInIcon from '@/component/icon/LinkedInIcon.component.vue';

import { userService } from '@/service/user/User.service';

interface AsideLink {
    title: string;
    url: string;
}

export default defineComponent({
    name: 'AsideComponent',

    components: {
        LinkComponent,
        GitHubIcon,
        LinkedInIcon,
    },

    setup() {
        const route = useRoute();

        const currentLocation = computed<string>(() => route?.path);

        const authDetails = userService.getAuthDetails();

        const links = computed<Array<AsideLink>>(() => [
            {
                title: 'About',
                url: '/about',
            },
            {
                title: 'Projects',
                url: '/projects',
            },
            {
                title: 'Blog',
                url: '/blog',
            },
            {
                title: authDetails.value?.user ? 'Your User' : 'Login',
                url: authDetails.value?.user ? '/user' : '/user/login',
            },
        ]);

        return {
            currentLocation,
            links,
        }
    },
});
