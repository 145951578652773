import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "flex-1" }
const _hoisted_2 = { class: "text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.details.isVisible)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(["user-message-component flex align-items-center", { [_ctx.details.type]: true }])
      }, [
        (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.icon), {
          width: 18,
          height: 18,
          class: "flex-auto"
        })),
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.details.message), 1)
        ])
      ], 2))
    : _createCommentVNode("", true)
}