
import { defineComponent } from 'vue';

import PageContentComponent from '@/component/layout/PageContent.component.vue';
import PageTitleComponent from '@/component/layout/PageTitle.component.vue';
import ButtonComponent from '@/component/Button.component.vue';
import UserMessageComponent from '@/component/UserMessage.component.vue';

export default defineComponent({
    name: 'NotFoundView',

    components: {
        PageContentComponent,
        PageTitleComponent,
        ButtonComponent,
        UserMessageComponent,
    },

    setup() {},
});
