import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["href", "title"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("a", {
    class: "link-component",
    target: "_blank",
    rel: "noopener noreferrer",
    href: _ctx.href,
    title: _ctx.title
  }, [
    _renderSlot(_ctx.$slots, "default")
  ], 8, _hoisted_1))
}