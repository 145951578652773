
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'LinkComponent',

    props: {
        href: String,
        title: String,
    },

    setup() {},
});
