
import { defineComponent } from 'vue';

import AsideComponent from '@/component/layout/Aside.component.vue';

export default defineComponent({
    name: 'App',

    components: {
        AsideComponent,
    },

    setup() {},
});
